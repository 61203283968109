/**
 * Useful functions for Cesium Layers
 */
import {
  UrlTemplateImageryProvider,
  BingMapsImageryProvider,
  BingMapsStyle,
  WebMapTileServiceImageryProvider,
  WebMercatorTilingScheme,
  Credit,
  NearFarScalar,
  Cartesian3,
  HeadingPitchRange,
  Math,
  CesiumTerrainProvider,
  IonResource,
  sampleTerrainMostDetailed,
  Cartographic,
  HeightReference,
  Color,
  LabelStyle,
  VerticalOrigin,
  Cartesian2,
  Entity,
  Transforms,
  Matrix4,
  Ellipsoid
} from "cesium/Build/Cesium/Cesium";
import i18n from "i18next";

import {
  removeCesiumImageryLayerByName,
  getColorShades,
  styleByEntityIndex
} from "components/Dashboard3D/CesiumLayerUtils";
import { EPSG_3857 } from "assets/global";
import { optimalPathVectorName } from "./CesiumConstants";

/**
 * Get datasource by name
 * @param {*} viewer
 * @param {*} searchName
 */
export function getDatasourceLayerByName(viewer, searchName) {
  let data = null;
  if (viewer) {
    viewer.dataSources.getByName(searchName).forEach(dataSource => {
      data = dataSource;
    });
  }
  return data;
}

/**
 * Get 3D basemap imageryProvider
 * @return  imageryProvider
 */
export function get3DProvider(background) {
  let imageryProvider;
  switch (background.provider) {
    case "Google maps":
      imageryProvider = new UrlTemplateImageryProvider({
        url: background.url,
        format: "image/jpeg"
      });
      break;
    case "Bing maps":
      let extra = background.extra_options;
      imageryProvider = new BingMapsImageryProvider({
        url: background.url,
        key: background.apikey,
        mapStyle: extra.hasOwnProperty("imagerySet")
          ? extra.imagerySet
          : BingMapsStyle.AERIAL
      });
      break;
    case "empty":
      imageryProvider = null;
      break;
    case "Local":
    case "XYZ":
    case "URL":
      imageryProvider = new UrlTemplateImageryProvider({
        url: background.url,
        format: "image/jpeg"
      });
      break;
    case "Local reverse":
      imageryProvider = new UrlTemplateImageryProvider({
        url: background.url + "/{z}/{x}/{reverseY}.png",
        format: "image/jpeg"
      });
      break;

    case "WMTS":
      if (!background.extra_options.layer) {
        console.log("layer parameter is missing in extra options");
        break;
      }
      if (!background.extra_options.style) {
        console.log(
          "style parameter is missing in extra options. In Geonode, it may be the layer name"
        );
        break;
      }

      let tileMatrixPrefix = "";
      let matrixSet = EPSG_3857;

      if (background.extra_options.tileMatrixPrefix)
        tileMatrixPrefix = background.extra_options.tileMatrixPrefix;

      if (background.extra_options.matrixSet)
        matrixSet = background.extra_options.matrixSet;

      let maximumLevel = 18;
      let matrixIds = new Array(maximumLevel + 1);
      let z;
      for (z = 0; z <= maximumLevel; ++z) {
        matrixIds[z] = tileMatrixPrefix + z;
      }
      imageryProvider = new WebMapTileServiceImageryProvider({
        url: background.url,
        layer: background.extra_options.layer,
        style: background.extra_options.style,
        format: "image/png",
        tileMatrixSetID: matrixSet,
        tileMatrixLabels: matrixIds,
        tilingScheme: new WebMercatorTilingScheme(),
        maximumLevel: maximumLevel,
        credit: new Credit("")
      });
      break;

    default:
      break;
  }
  return imageryProvider;
}

/**
 * Change the Cesium BaseMap to the provided baseMap Definition
 * BaseMap definitions contain a list of layers to load as a
 * BaseMap.
 *
 * @param {*} Cesium viewer to modify
 * @param {obj} basemapDef a definiton of a Pathfinder Basemap
 */
export function changeCesiumBasemap(viewer, basemapDef) {
  removeCesiumImageryLayerByName(viewer, "BaseMap");

  //Inverse loop since we want those imagery layers at the bottom
  //ordered as they come
  const layers = basemapDef?.layers ?? [];
  for (let i = layers.length - 1; i >= 0; i--) {
    let imageryProvider = get3DProvider(layers[i]);
    // Remove the old base maps
    let iLayers = viewer.scene.globe.imageryLayers;
    // Add new basemap layer
    if (imageryProvider) {
      let imgLayer = iLayers.addImageryProvider(imageryProvider);
      imgLayer.name = "BaseMap";
      iLayers.lowerToBottom(imgLayer);
      viewer.scene.requestRender();
    }
  }
}

/**
 * Change Cesium globe background color
 * @param {*} viewer
 * @param {*} color
 */
export function changeCesiumBackgroundColor(viewer, color = "#FFFFFF") {
  viewer.scene.globe.baseColor = getColorByString(color);
}

/**
 * Change Cesium globe transparency
 * @param {*} viewer Cesium viewer instance to change
 * @param {*} enabled. Enable or disable the terrain transparency
 */
export function setCesiumBackgroundTransparency(viewer, enabled = true) {
  let scene = viewer.scene;
  let globe = scene.globe;

  globe.translucency.frontFaceAlphaByDistance = new NearFarScalar(
    1500,
    0.5,
    5000,
    1.0
  );
  globe.undergroundColor = getColorByString("#ece2c6");
  scene.screenSpaceCameraController.enableCollisionDetection = !enabled;
  globe.translucency.enabled = enabled;
}

/**
 * Get tooltip div object
 */
export function getCesiumTooltip() {
  let tooltip = document.getElementById("cesium-tooltip");
  tooltip.style.display = "none";
  tooltip.innerHTML = "";
  return tooltip;
}

/**
 * Zoom datasource by name
 * @param {*} viewer
 * @param {*} name
 */
export function zoomDatasourceByName(viewer, name) {
  let datasource = getDatasourceLayerByName(viewer, name);
  viewer.flyTo(datasource);
  viewer.scene.requestRender();
}

/**
 * Method that flies to the given coordinates in the globe with a cenital view.
 * Height is automaticaly set to the terrain height + 600m zooming with
 * altitudeToTerrain, otherwise the height is just set to 600
 *
 * @param {object} viewer Cesium viewer instance
 * @param {number} lon longitude to zoom to
 * @param {number} lat latitude to zoom to
 * @param {boolean} altitudeToTerrain automatically adjust the zoom point Z
 * position to the terrain altitude
 */
export async function cesiumZoomToCoordinates(
  viewer,
  lon,
  lat,
  altitudeToTerrain = false
) {
  if (altitudeToTerrain) {
    getCesiumElevatonPoints([cartographicFromDegrees(lon, lat)])
      .then(point => {
        //Fly To specific coordinate with an extra 1km height
        viewer.camera.flyTo({
          destination: Cartesian3.fromDegrees(lon, lat, point[0].height + 600)
        });
      })
      .catch(error => {
        console.error("Cesium Failed to retrieve altitude");
      });
  } else {
    viewer.camera.flyTo({
      destination: Cartesian3.fromDegrees(lon, lat, 1000),
      offset: new HeadingPitchRange(
        Math.toRadians(0),
        Math.toRadians(-90.0),
        1000
      )
    });
  }
}

/**
 * Find scenario object by Id in resultContext
 * @param {*} scenarioId
 * @param {*} resultContext
 */
export function findScenarioById(scenarioId, resultContext) {
  return resultContext.resultsState.resultScenariosVector.find(
    element => element.id === parseInt(scenarioId)
  );
}

/**
 * create Elevation Profile add elevation to set of points
 * @param {Array} coord
 * @param {Number} terrainAsset
 */
export const getCesiumElevatonPoints = async (coord, terrainAsset = 1) => {
  let terrainProvider = new CesiumTerrainProvider({
    url: IonResource.fromAssetId(terrainAsset)
  });
  return await sampleTerrainMostDetailed(terrainProvider, coord);
};

/**
 * Convert degrees to cartografic coordinates
 * @param {*} longitude
 * @param {*} latitude
 * @returns
 */
export const cartographicFromDegrees = (longitude, latitude) => {
  return new Cartographic.fromDegrees(longitude, latitude);
};

/**
 * Find scenario Optimal path by Id
 * @param {*} scenario
 * @param {*} appContext
 */
export function findOptimalPathByScenario(scenario) {
  return scenario.paths.find(element => element.is_optimal_path === true);
}

/**
 * Raise to top imageryLayer by name (Z Index)
 * @param {*} viewer
 * @param {*} name
 */
export function raiseToTopImageryLayerByName(viewer, name) {
  let iLayers = viewer.imageryLayers;
  let i;
  let l = iLayers.length;
  for (i = 0; i < l; i++) {
    if (iLayers._layers[i].name.indexOf(name) >= 0) {
      iLayers.raiseToTop(iLayers._layers[i]);
    }
  }
  viewer.scene.requestRender();
}

/**
 * Remove Map Entity by ID
 * @param {*} datasource
 * @param {*} id
 */
export function removeDatasourceEntityById(datasource, id) {
  const entity = datasource?.entities.getById(id);
  if (entity) {
    datasource.entities.remove(entity);
  }
}

/**
 * Creates a simple point-type entity.
 * @param {*} viewer
 * @param {*} coord
 * @param {*} name
 */
export function drawSimplePoint(viewer, coord, name) {
  viewer.entities.add({
    name: name,
    position: Cartesian3.fromDegrees(coord[0], coord[1]),
    point: {
      heightReference: HeightReference.CLAMP_TO_GROUND,
      pixelSize: 2,
      color: Color.RED,
      outlineColor: Color.WHITE,
      outlineWidth: 1
    },
    label: {
      text: name,
      font: "14pt monospace",
      style: LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 1,
      verticalOrigin: VerticalOrigin.BOTTOM,
      pixelOffset: new Cartesian2(0, -9)
    }
  });
}

/**
 * Change IMagery layers alpha value
 * @param {*} viewer
 * @param {*} name
 * @param {*} alpha
 */
export function changeAlphaImageryLayerByName(viewer, name, alpha) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    if (viewer.imageryLayers._layers[i].name === name) {
      viewer.imageryLayers._layers[i].alpha = Number(alpha);
    }
  }
  viewer.scene.requestRender();
}

export function getPolylineColor(entities, dragid, lineName) {
  let entitiesValues = entities.values;

  let i;
  let l = entitiesValues.length;

  for (i = 0; i < l; i++) {
    let entity = entitiesValues[i];
    if (entity.group === dragid && entity.name === lineName) {
      return entity.polyline.material;
    }
  }
}

/**
 * Remove by condition
 * @param {*} viewer
 * @param {*} entities
 * @param {*} param
 * @param {*} value
 * @param {*} equal
 */
export function removeEntityByCondition(
  viewer,
  entities,
  param,
  value,
  equal = true
) {
  entities.suspendEvents();
  let entitiesValues = entities.values;
  let i;
  for (i = 0; i < entitiesValues.length; i++) {
    if (entitiesValues.length !== 0) {
      let entity = entitiesValues[i];
      if (equal) {
        if (entity[param] === value) {
          entities.remove(entity);
          i--;
        }
      } else {
        if (entity[param] !== value) {
          entities.remove(entity);
          i--;
        }
      }
    } else {
      break;
    }
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Show/hide Highlight cables in optimal path
 * @param {*} viewer
 * @param {*} visible
 */
export function toggleHighlight(viewer, visible) {
  let dataSource = getDatasourceLayerByName(viewer, "optimalPathVector");
  toggleEntityVisiblityByProperty(
    viewer,
    dataSource.entities,
    "name",
    "Highlight",
    visible
  );
}

/**
 * Show/hide Highlight cables in optimal path by id
 * @param {*} viewer
 * @param {*} visible
 * @param {*} id
 */
export function toggleHighlightById(viewer, visible, id) {
  let dataSource = getDatasourceLayerByName(viewer, "optimalPathVector");

  toggleEntityVisiblityByProperty(
    viewer,
    dataSource.entities,
    "group",
    id,
    visible
  );
}

/**
 * Tooggle datasource visibility by name
 * @param {*} viewer
 * @param {*} searchName
 * @param {*} visible
 */
export function toggleDatasourceVisiblity(viewer, searchName, visible) {
  let dataSource = getDatasourceLayerByName(viewer, searchName);
  dataSource.show = visible;
  viewer.scene.requestRender();
}

/**
 * Calculate Cartesian 3D distance from a to b
 * @param {*} a
 * @param {*} b
 */
export function computeCartesian3Distance(a, b) {
  return parseFloat(Cartesian3.distance(a, b)).toFixed(2);
}

/**
 * Tooggle entity Ellipse visibility by name
 * @param {*} viewer
 * @param {*} searchName
 * @param {*} visible
 */
export function toggleEntityEllipseVisiblity(
  viewer,
  entities,
  searchName,
  visible
) {
  entities.suspendEvents();
  let i;
  let values = entities.values;
  let l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i].name === searchName) values[i].ellipse.show = visible;
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Tooggle entity Ellipse visibility by serial (pylon number).
 * @param {*} viewer
 * @param {*} pathId
 * @param {*} serial
 * @param {*} visible
 */
export function toggleEntityEllipseVisiblityBySerial(
  viewer,
  pathId,
  serial,
  visible
) {
  const dataSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

  const entities = dataSource.entities;

  entities.suspendEvents();

  let i;
  let values = entities.values;
  const l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i]?.group === pathId) {
      if (values[i].data?.serial === serial) {
        if (values[i].ellipse?.show) values[i].ellipse.show = visible;
      }
    }
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Tooggle entity visibility by name
 * @param {*} viewer
 * @param {*} searchName
 * @param {*} visible
 */
export function toggleEntityVisiblityByProperty(
  viewer,
  entities,
  param,
  searchName,
  visible
) {
  entities.suspendEvents();
  let i;
  let values = entities.values;
  let l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i][param] === searchName) {
      values[i].show = visible;
    }
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Change Polygon datasource by name
 * @param {*} viewer
 * @param {*} name
 * @param {*} color
 */
export function changeLayerColorByName(viewer, name, color, isBuffer = false) {
  let dataSource = getDatasourceLayerByName(viewer, name);
  if (dataSource) {
    let entities = dataSource.entities;
    entities.suspendEvents();
    let values = entities.values;
    let layerColor = fromCssColorString(color);
    let polygonMat = layerColor;
    let shades = [];
    if (isBuffer) {
      let geometriesLength = values[0].properties.geometriesLength.getValue();
      shades = getColorShades(color, geometriesLength);
    }
    // Change entity color
    for (const entity of values) {
      // Check if have shades
      if (isBuffer && shades.length > 0) {
        let findex = entity.properties.index.getValue();
        polygonMat = styleByEntityIndex(shades, findex);
      }
      if (entity.polygon) entity.polygon.material = polygonMat;
      if (entity.point) entity.point.color = layerColor;
      if (entity.polyline) entity.polyline.material.color = layerColor;
    }
    // Resume and request
    entities.resumeEvents();
    viewer.scene.requestRender();
  }
}

/**
 *  Change Polyline datasource materrial color by name
 * @param {*} entities
 * @param {*} id
 * @param {*} color
 */
export function changePolylineMaterialByName(entities, id, color) {
  entities.suspendEvents();
  let i;
  let entitiesValues = entities.values;
  let l = entitiesValues.length;
  let newColor = Color.fromCssColorString(color);

  for (i = 0; i < l; i++) {
    let entity = entitiesValues[i];
    if (entity.group === id && entity.name === "line") {
      switch (entity.polyline.material.constructor.name) {
        default:
        case "ColorMaterialProperty":
          entity.polyline.material = newColor;
          break;
        case "PolylineDashMaterialProperty":
          entity.polyline.material.color = newColor;
          break;
      }
    }
  }
  entities.resumeEvents();
}

/**
 * Get cesium color by name
 * @param {*} colorName
 * @param {*} alpha
 */
export const getColorByName = (colorName, alpha) => {
  let color = Color[colorName.toUpperCase()];
  return Color.fromAlpha(color, parseFloat(alpha));
};

/**
 * Get cesium color by string with alpha
 * @param {*} colorName
 * @param {*} alpha
 */
export const getColorByString = (colorName, alpha = 1.0) => {
  let color = Color.fromCssColorString(colorName);
  return Color.fromAlpha(color, parseFloat(alpha));
};

/**
 * Get cesium color by string
 * @param {*} colorName
 */
export const fromCssColorString = colorName => {
  return Color.fromCssColorString(colorName);
};

/**
 * Degress to radians
 * @param {*} val
 */
export const degreesToRadians = val => {
  return Math.toRadians(val);
};

/**
 * Radians to Degress
 * @param {*} val
 */
export const radianToDegrees = val => {
  return Math.toDegrees(val);
};

/**
 * Cartographic from cartesian
 * @param {*} val
 * @returns
 */
export const CartographicFromCartesian = val => {
  return Cartographic.fromCartesian(val);
};

/**
 * Create Geocoder Billboard
 * @param {*} lon
 * @param {*} lat
 * @param {*} color
 */
export function createGeocoderIcon(lon, lat, color) {
  return new Entity({
    position: Cartesian3.fromRadians(lon, lat),
    name: "GeocoderPin",
    billboard: {
      image: "./iconW.png",
      color: color,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      verticalOrigin: VerticalOrigin.BOTTOM,
      scaleByDistance: new NearFarScalar(1.5e1, 1.0, 1.5e7, 0.5),
      scale: 0.1,
      heightReference: HeightReference.CLAMP_TO_GROUND
    }
  });
}

/**
 * Change alpha value entity color
 * @param {*} datasource
 * @param {*} id
 */
export function changeFlagAlphaById(datasource, id, alpha = 1) {
  let entity = datasource.entities.getById(id);
  if (entity) {
    let color = entity.billboard.color.getValue().withAlpha(alpha);
    entity.billboard.color.setValue(color);
    if (alpha === 0) {
      entity.label.show = false;
    } else {
      entity.label.show = true;
    }
  }
}

/**
 * Draw start/end flags
 * @param {*} projectVector
 * @param {*} coordinates
 * @param {*} id
 * @param {*} isStart
 * @returns
 */
export function drawProjectPoints(
  projectVector,
  coordinates,
  id,
  isStart = true
) {
  if (!coordinates) return;

  let flagEntity = projectVector.entities.getById(id);

  // Update the current point if exist
  if (flagEntity) {
    flagEntity.position = Cartesian3.fromDegrees(
      coordinates[0],
      coordinates[1]
    );
  }

  // Create new flag if not exist
  else {
    let name = isStart ? "common.start" : "common.end";
    let color = isStart
      ? getColorByString("#0fff7b")
      : getColorByString("#0fa7ff");
    projectVector.entities.add(
      createFlagEntity(id, coordinates, color, i18n.t(name).toUpperCase())
    );
  }
}

/**
 * Create Project Start/End Flag
 * @param {*} coordinates
 * @param {*} image
 */
export function createFlagEntity(id, coordinates, color, text) {
  return new Entity({
    position: Cartesian3.fromDegrees(coordinates[0], coordinates[1]),
    billboard: {
      image: "./iconW.png",
      color: color,
      verticalOrigin: VerticalOrigin.BOTTOM,
      scaleByDistance: new NearFarScalar(1.5e1, 1.0, 1.5e7, 0.5),
      scale: 0.1,
      heightReference: HeightReference.CLAMP_TO_GROUND
    },
    label: {
      text: text,
      font: "bold 18px Calibri",
      fillColor: getColorByString("#333333"),
      outlineColor: getColorByString("#ffffff"),
      outlineWidth: 4,
      style: LabelStyle.FILL_AND_OUTLINE,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      pixelOffset: new Cartesian2(0, -2)
    },
    id: id
  });
}

/**
 * Create Intermediate Entity
 * @param {*} coordinates
 * @param {*} image
 * Is not posible add billboard and point https://github.com/CesiumGS/cesium/issues/7357
 */
export function createIntermediateEntity(point, text) {
  return new Entity({
    position: point,
    point: {
      heightReference: HeightReference.CLAMP_TO_GROUND,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      color: getColorByString("#00BEF3", 0.2),
      pixelSize: 10,
      outlineColor: getColorByString("#00BEF3"),
      outlineWidth: 2
    },
    label: {
      text: text,
      font: "bold 16px sans-serif",
      fillColor: getColorByString("#333333"),
      outlineColor: getColorByString("#ffffff"),
      outlineWidth: 4,
      showBackground: true,
      backgroundColor: getColorByString("#F92E05"),
      style: LabelStyle.FILL_AND_OUTLINE,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      pixelOffset: new Cartesian2(0, -18)
    }
  });
}

/**
 * Remove Cesium Entity by Id
 * @param {*} viewer
 * @param {*} name
 */
export function removeMapEntityById(viewer, name) {
  let entityToRemove = viewer.entities.getById(name);
  if (entityToRemove) {
    viewer.entities.remove(entityToRemove);
  }
  viewer.scene.requestRender();
}

/**
 * Draw Stree View Point
 * @param {*} viewer
 * @param {*} coord
 * @param {*} name
 */
export function drawSVPoint(viewer, coord, name) {
  let heading = 0;
  let pitch = 0;
  let roll = 0;
  let currentPosition = Cartesian3.fromDegrees(coord[0], coord[1]);
  var hpr = new HeadingPitchRoll(heading, pitch, roll);

  var orientation = Transforms.headingPitchRollQuaternion(currentPosition, hpr);

  viewer.entities.add({
    id: name,
    position: Cartesian3.fromDegrees(coord[0], coord[1]),
    orientation: orientation,
    model: {
      uri: "./arrow.glb",
      color: Color.BLUE,
      allowPicking: false,
      show: true,
      scale: 30.0,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      minimumPixelSize: 30,
      maximumScale: 200000
    }
  });
}

/**
 * Prevent go to underground
 * @param {*} map
 * @param {*} terrain_height
 */
export const unburrow = (map, terrain_height) => {
  let cp = map.scene.camera.position;
  let camera_height =
    map.scene.globe.ellipsoid.cartesianToCartographic(cp).height;

  //Determine terrain relative height (edist)
  let edist = camera_height - terrain_height;

  //Unburrow code
  if (edist < 5) {
    let GD_transform = Transforms.eastNorthUpToFixedFrame(
      cp,
      map.scene.globe.ellipsoid,
      new Matrix4()
    );
    let GD_ENU_U = new Cartesian3(
      GD_transform[8],
      GD_transform[9],
      GD_transform[10]
    );
    let temp = new Cartesian3();
    Cartesian3.multiplyByScalar(
      GD_ENU_U,
      terrain_height - camera_height + 100,
      temp
    );
    map.scene.requestRender();
    map.scene.camera.position.x += temp.x;
    map.scene.camera.position.y += temp.y;
    map.scene.camera.position.z += temp.z;
    map.scene.requestRender();
  }
};

/**
 * Get cartographic coordinates in the picked ray
 * @param {*} pos
 * @returns
 */
export const getCartographic = (map, pos) => {
  let camera = map.camera;
  let scene = map.scene;

  let position1;
  let cartographic;
  let ray = camera.getPickRay(pos);
  position1 = ray ? scene.globe.pick(ray, map.scene) : false;

  cartographic = position1
    ? Ellipsoid.WGS84.cartesianToCartographic(position1)
    : false;
  return cartographic;
};

/**
 * Change Cesium Map Cursor
 * @param {*} map
 * @param {*} cursor
 */
export const setMapCursor = (map, cursor = "crosshair") => {
  map.container.style.cursor = cursor;
};
