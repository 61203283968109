/**
 * Wiki reference:
 * https://gitlab.com/stefano.g/pathfinder_dev/-/wikis/Frontend/Context/CostModelsContext
 */

import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { getScenarioConfigs } from "services/scenario";
import { getCostModelDocs, getCostModelParameters } from "services/models";
import { getAvailableCostModels } from "services/cost_models";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

const initialState = {
  costModelsAvailable: null,
  costModelConfigs: null,
  costModelUIConfigs: new Map(),
  costModelUIConfigsLoadStatus: "not-fetched" // "not-fetched" | "fetching" | "fetched"
};

function costModelsReducer(state, action) {
  switch (action.type) {
    case "COST_MODEL_CONFIGS_SET": {
      return {
        ...state,
        costModelConfigs: action.payload.costModelConfigs
      };
    }

    case "COST_MODELS_AVAILABLE_SET": {
      return {
        ...state,
        costModelsAvailable: action.payload.costModelsAvailable
      };
    }

    // Set cost model UI Configs
    case "COST_MODEL_UI_CONFIGS_SET": {
      return {
        ...state,
        costModelUIConfigs: action.payload?.costModelUIConfigsMap
      };
    }

    // Update or set a single cost model element
    case "COST_MODEL_UI_CONFIGS_UPDATE": {
      return {
        ...state,
        costModelUIConfigs: action.payload.costModelUIConfigsMap
      };
    }

    // Set the status of the cost model UI Configs load
    case "COST_MODEL_UI_CONFIGS_LOAD_STATUS_SET": {
      return {
        ...state,
        costModelUIConfigsLoadStatus:
          action.payload.costModelUIConfigsLoadStatus
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of Cost Models Provider
 * @param { Object } props - Properties of the component
 * @param { ReactNode } props.children - Children components
 * @returns node
 */
export default function CostModelsProvider({ children }) {
  const [state, dispatch] = React.useReducer(costModelsReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

CostModelsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * Hook to access and manage the state of the Cost Models Context
 */
const useCostModelsContext = (_scenarioConfigId = null) => {
  const [scenarioConfigId, setScenarioConfigId] = useState(_scenarioConfigId);
  const costModelsState = useContext(StateContext);
  const costModelsDispatch = useContext(DispatchContext);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // EFFECTS  //////////////////////////

  /**
   * COST MODELS AVAILABLE RETRIEVAL
   *
   * Effect that retrieves from back-end the available
   * cost models for the scenario config.
   *
   * If costModelsAvailable is not set and we have an
   * scenarioConfigId, we proceed to retrieve the
   * available cost models for the scenario config.
   */
  useEffect(() => {
    if (!costModelsState.costModelsAvailable && scenarioConfigId) {
      getScenarioConfigs(scenarioConfigId)
        .then(res => {
          costModelsDispatch({
            type: "COST_MODELS_AVAILABLE_SET",
            payload: { costModelsAvailable: [...res.cost_models] }
          });
        })
        .catch(() => {
          enqueueSnackbar(t("CostModelConfig.ErrorGettingModels"), {
            variant: "error"
          });
        });
    }
  }, [
    costModelsDispatch,
    costModelsState.costModelsAvailable,
    enqueueSnackbar,
    scenarioConfigId,
    t
  ]);

  /**
   * COST MODEL CONFIGS RETRIEVAL
   *
   * Effect that iterates the available cost models (costModelsAvailable)
   * to retrieve the model configs for each one.
   *
   * If costModelConfigs and we have costModelsAvailable and
   * scenarioConfigId, we proceed to iterate the available cost models
   * for the scneario config and call back-end to retrieve its
   * config.
   *
   * If the retrieved model config has a 'layer_p' property and
   * it's not empty or model config has a 'section_p' property and
   * it's not empty, we push it to costModelConfigs.
   *
   */
  useEffect(() => {
    if (
      !costModelsState.costModelConfigs &&
      costModelsState.costModelsAvailable &&
      scenarioConfigId
    ) {
      let configs = [];
      let index;
      let modelsLength = costModelsState.costModelsAvailable.length;

      // Iteation of available cost models.
      for (index = 0; index < modelsLength; index++) {
        const modelName = costModelsState.costModelsAvailable[index];
        // We call back-end to retrieve cost data for
        // each model.
        getCostModelParameters(modelName)
          .then(config => {
            // Store cost data in state if layer_p
            // is not empty or section_p is not empty.
            if (config.layer_p.length > 0 || config.section_p.length > 0) {
              configs.push({
                name: modelName,
                globalParams: config.global_p,
                layerParams: config?.layer_p || [],
                sectionParams: config?.section_p || []
              });
            }
          })
          .catch(() => {
            enqueueSnackbar(t("CostModelConfig.ErrorGettingModelConfigs"), {
              variant: "error"
            });
          });
      }

      costModelsDispatch({
        type: "COST_MODEL_CONFIGS_SET",
        payload: { costModelConfigs: configs }
      });
    }
  }, [
    costModelsState.costModelsAvailable,
    costModelsState.costModelConfigs,
    scenarioConfigId,
    costModelsDispatch,
    enqueueSnackbar,
    t
  ]);

  // PRIVATE METHODS   //////////////////////////
  /**
   * Private method to update cost model configs.
   *
   * Given an array of available cost model names, it iterates
   * the array to call back-end and retrieve the model
   * config for each one.
   *
   * If success it updates the context state 'costModelConfigs'.
   *
   * @param { aray } availableModels Array with the available cost models
   */
  const retrieveCostModelConfigs = availableModels => {
    let modelsLength = availableModels.length;
    let configs = [];
    let index;

    // Iteation of available cost models.
    for (index = 0; index < modelsLength; index++) {
      const modelName = availableModels[index];
      // We call back-end to retrieve cost data for
      // each model.
      getCostModelParameters(modelName)
        .then(config => {
          // Store cost data in state if layer_p
          // is not empty or section_p is not empty.
          if (config.layer_p.length > 0 || config.section_p.length > 0) {
            configs.push({
              name: modelName,
              globalParams: config.global_p,
              layerParams: config?.layer_p || [],
              sectionParams: config?.section_p || []
            });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("CostModelConfig.ErrorGettingModelConfigs"), {
            variant: "error"
          });
        });
    }

    // Update the state with the cost model configs and
    // the cost models available
    costModelsDispatch({
      type: "COST_MODEL_CONFIGS_SET",
      payload: {
        costModelConfigs: configs
      }
    });
  };

  // PUBLIC METHODS   //////////////////////////
  /**
   * Public method to update the scenario config ID
   * @param {String} id - Scenario config ID
   * @returns void
   */
  const updateScenarioConfigID = id => {
    setScenarioConfigId(id);
  };

  /**
   * Method to set the cost model UI Configs
   * @param {Array} value - Array of cost model UI Config elements
   * @returns void
   */
  const setCostModelUIConfigs = value => {
    // Do some preprocessing to convert the received cost model UI Configs array into a Map
    const newMap = new Map();
    const costModelUIConfigs = value || [];

    for (const element of costModelUIConfigs) {
      // It must have all the minimum properties or we will skip it
      if (!element?.class_name || !element?.label || !element?.label_short) {
        continue;
      }

      // If it is a valid element, we add it to the new map
      // and we index it by its class_name
      newMap.set(element.class_name, element);
    }
    //

    costModelsDispatch({
      type: "COST_MODEL_UI_CONFIGS_SET",
      payload: { costModelUIConfigsMap: newMap }
    });
  };

  /**
   * Method to update a single cost model UI Config element
   * @param {Object} value - Cost model UI Config element
   * @returns void
   */
  const updateCostModelUIConfig = value => {
    // Do some preprocessing to convert the received cost model UI Config item into a Map containing the previous elements and the new one (if it is valid)

    const itemToSet = value || {};

    // Check if it has the minimum properties
    if (
      !itemToSet?.class_name ||
      !itemToSet?.label ||
      !itemToSet?.label_short
    ) {
      return;
    }

    // We create a new map to avoid mutations
    const newMap = new Map(costModelsState.costModelUIConfigs);
    newMap.set(itemToSet.class_name, itemToSet);

    costModelsDispatch({
      type: "COST_MODEL_UI_CONFIGS_UPDATE",
      payload: { costModelUIConfigsMap: newMap }
    });
  };

  /**
   * Public method to update the available cost models for
   * the current scenario config.
   *
   * It calls the back-end to retrieve avaliable cost models
   * and update the context state 'costModelsAvailable'.
   *
   * If success, it calls the private method to retrieve
   * cost model configs for the available cost models.
   */
  const updateCostModels = async (id = undefined) => {
    let _scenarioConfigId = scenarioConfigId;
    if (id) {
      updateScenarioConfigID(id);
      _scenarioConfigId = id;
    }

    // To avoid querying a lot of times the scenario config, we skip this logic if it is already fetching
    // since this function could be called at the same time in several places (sadly, in the future I hope we improve the logic in those places)
    if (
      _scenarioConfigId &&
      costModelsState.costModelUIConfigsLoadStatus !== "fetching"
    ) {
      costModelsDispatch({
        type: "COST_MODEL_UI_CONFIGS_LOAD_STATUS_SET",
        payload: { costModelUIConfigsLoadStatus: "fetching" }
      });

      try {
        const res = await getAvailableCostModels();
        costModelsDispatch({
          type: "COST_MODELS_AVAILABLE_SET",
          payload: { costModelsAvailable: [...res.map(c => c.class_name)] }
        });
        retrieveCostModelConfigs(res.map(c => c.class_name));

        // If the cost model UI Configs are not fetched, we fetch them
        if (costModelsState.costModelUIConfigsLoadStatus === "not-fetched") {
          const costModelsToSet = [];
          for (const costModel of res) {
            const costModelName = costModel.class_name;
            // If the cost model UI Config element is already cached, we skip it
            if (costModelsState.costModelUIConfigs.has(costModelName)) continue;

            try {
              const costModelDocs = await getCostModelDocs(costModelName);
              costModelsToSet.push(costModelDocs);
            } catch (err) {
              console.error(err);
            }
          }

          if (costModelsToSet.length > 0) {
            setCostModelUIConfigs(costModelsToSet);
          }
        }

        // In any case, at the end we set the load status to fetched
        costModelsDispatch({
          type: "COST_MODEL_UI_CONFIGS_LOAD_STATUS_SET",
          payload: { costModelUIConfigsLoadStatus: "fetched" }
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("CostModelConfig.ErrorGettingModels"), {
          variant: "error"
        });

        costModelsDispatch({
          type: "COST_MODEL_UI_CONFIGS_LOAD_STATUS_SET",
          payload: { costModelUIConfigsLoadStatus: "not-fetched" }
        });
      }
    }
  };

  /**
   * @typedef {Object} CostModelData
   * @property {string} class_name - The name of the cost model.
   * @property {string} label - The label of the cost model.
   * @property {string} label_short - The short label of the cost model.
   * @property {string} doc - The documentation of the cost model.
   */

  /**
   * Public method to get the cost model UI Data. In case the
   * cost model UI Data is not available, it returns a default
   * object with the class_name as label and label_short.
   * @param {string} costModelName - Cost model name
   * @returns {CostModelData} Cost model UI Config
   */
  function getCostModelUIData(costModelName) {
    return (
      costModelsState.costModelUIConfigs.get(costModelName) || {
        class_name: costModelName,
        label: costModelName,
        label_short: costModelName,
        doc: ""
      }
    );
  }

  return {
    costModelsState,
    updateCostModels,
    setCostModelUIConfigs,
    updateCostModelUIConfig,
    updateScenarioConfigID,
    getCostModelUIData
  };
};

export { CostModelsProvider, useCostModelsContext };
