import {
  EDGE_TYPE_OVERHEAD,
  EDGE_TYPE_TUNNEL,
  EDGE_TYPE_UNDER
} from "assets/global";
import i18n from "i18n";
import {
  GeometryCollection,
  Point,
  LineString,
  Circle as CircleGeom
} from "ol/geom";
import {
  Circle as CircleStyle,
  Style,
  Text,
  Fill,
  Stroke,
  Circle
} from "ol/style.js";

import Icon from "ol/style/Icon";
import { getBezier } from "utils/OpenLayers/StyleUtils";
import { getPointResolution } from "ol/proj";

// Rendering priority. Higher numbers draw on top.
const Z_INDEX_LINES = 1;
const Z_INDEX_PYLONS = 2;
const Z_INDEX_PYLON_EDIT = 3;
const Z_INDEX_FEATURE_EDIT = 3;
const Z_INDEX_LABELS_TECHNOLOGIES = 4;
const Z_INDEX_LABELS_SECTIONS = 5;
const Z_INDEX_CONNECTORS = 6;
const LABEL_OFFSET_X = 10;
const LABEL_OFFSET_Y = 25;
const TECH_LABEL_OFFSET_X = -10;
const TECH_LABEL_OFFSET_Y = -25;

const NODE_CIRCLE_GEOM_MAX_RADIUS = 300;
const NODE_CIRCLE_GEOM_MIN_RADIUS = 1;
const NODE_CIRCLE_GEOM_RES_MULTIPLIER = 4;

const NODE_VISIBILITY_RESOLUTION_THRESHOLD = 200;

/**
 * Class used to declare the common styles used on OL 2D map
 */
export class OLMapStyles {
  constructor() {
    /**
     * Style used in the follow cursor point
     */
    this.cursorPoint = new Style({
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.7)"
        }),
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    });

    /**
     * Returns a node geometry (Circle) according to the provided resolution.
     * With this geometry generator we create circles at a correct size in all
     * zooms. Avoiding the huge circles at high resolutions and tiny ones at low
     * resolutions that occurr when simply using an Image.
     *
     * @param {Array[number]} coords Pair of coordinates representing a single point
     * @param {Number} resolution Resolution to generate the circle for.
     * @returns {CircleGeom} an OpenLayers Circle Geometry
     */
    this.ResolutionAwareNodeGeometry = (coords, resolution) => {
      return new CircleGeom(
        coords,
        Math.min(
          Math.max(
            resolution * NODE_CIRCLE_GEOM_RES_MULTIPLIER,
            NODE_CIRCLE_GEOM_MIN_RADIUS
          ),
          NODE_CIRCLE_GEOM_MAX_RADIUS
        )
      );
    };

    /**
     * Draw identify point
     */
    this.drawIdentifyAttributePoint = new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.4)"
      }),
      stroke: new Stroke({
        color: "#00BEF3",
        lineDash: [10, 10],
        width: 2
      }),
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: "#00BEF3"
        }),
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.4)"
        })
      })
    });

    /**
     * Measure layer style
     */
    this.measureLayer = new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)"
      }),
      stroke: new Stroke({
        color: "#00BEF3",
        width: 2
      }),
      image: new Circle({
        radius: 7,
        fill: new Fill({
          color: "#00BEF3"
        })
      })
    });

    /**
     * Measure interaction style
     */
    this.measureInteraction = new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)"
      }),
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        lineDash: [10, 10],
        width: 2
      }),
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.7)"
        }),
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    });
    // Street View layer Style
    this.SVStyle = new Style({
      image: new Icon({
        anchor: [0.5, 32],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: "./google-maps-arrow-icon.png",
        size: [48, 48],
        scale: 1.0
      })
    });

    /**
     * Start point style
     * @param {*} feature
     * @returns Style
     */
    this.styleStart = feature => {
      return new Style({
        // Style definition for start point

        image: new Icon({
          anchorOrigin: "bottom-left",
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          anchor: [0.5, -0.2],
          scale: 0.1,
          color: "#0fff7b",
          src: "./iconW.png",
          opacity: feature.values_.type === "ghost" ? 0.5 : 1
        }),
        text: new Text({
          font: "bold 14px/24px sans-serif",
          text: i18n.t("common.start").toUpperCase(),
          fill: new Fill({
            color: "#333333"
          }),
          stroke: new Stroke({
            color: "#EEE",
            width: 3
          })
        })
      });
    };

    /**
     * End points style
     * @param {*} feature
     * @returns Style
     */
    this.styleEnd = feature => {
      return new Style({
        // Style definition for start point
        image: new Icon({
          anchorOrigin: "bottom-left",
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          anchor: [0.5, -0.2],
          scale: 0.1,
          color: "#0fa7ff",
          src: "./iconW.png",
          opacity: feature.values_.type === "ghost" ? 0.5 : 1
        }),
        text: new Text({
          font: "bold 14px/24px sans-serif",
          text: i18n.t("common.end").toUpperCase(),
          fill: new Fill({
            color: "#333333"
          }),
          stroke: new Stroke({
            color: "#EEE",
            width: 3
          })
        })
      });
    };

    /**
     * Area style
     */
    this.styleArea = new Style({
      fill: new Fill({
        color: "rgba(0,0,0,0)"
      }),
      stroke: new Stroke({
        color: "rgb(115,186,250)",
        width: 3
      })
    });

    /**
     * Intermediate Points style
     * @param {*} feature
     * @returns
     */
    this.styleInt = feature => {
      return new Style({
        // Style definition for intermediate points
        image: new Icon({
          anchorOrigin: "bottom-left",
          anchorXUnits: "fraction",
          anchor: [0.5, 0],
          scale: 0.07,
          src: "./iconfill.png"
        }),
        text: new Text({
          font: "bold 14px/24px sans-serif",
          text: feature.values_.markerID,
          fill: new Fill({
            color: "#333333"
          }),
          stroke: new Stroke({
            color: "#EEE",
            width: 3
          }),
          offsetY: -18
        })
      });
    };

    /**
     * Given a set of coordinates and a type, returns a list of styles according
     * to the predefined styles by pathStyleEdgeBase, pathStyleEdgeTop and
     * pathStyleNodes. Will override color on the styles that support so
     * and show or hide nodes according to the input parameters.
     *
     * NOTES: On large paths, providing the showNodes can get very slow
     * with this implementation (it was already slow before). The option
     * provided by OL in their examples seems to be handling those points
     * From a different Layer (WebGlPointsLayer), but since we're just
     * Styling LineStrings for now, we keep this approach. If we have
     * to be faster, this should be handled elsewhere (not at style level)
     *
     * @param {Array[Array]} coordinates Array of Array of coordinates to apply this style to
     * @param {string} type              what type we'll assign to those coordinates
     * @param {string} color             color to override in the style
     * @param {boolean} showNodes        Present the Nodes style or not
     * @returns {array} Array of styles
     */
    this.generateStyleForSection = (
      coordinates,
      type,
      color,
      resolution,
      showNodes,
      showLabels,
      label,
      strokeWidth
    ) => {
      let styles = [];

      // TODO Ideally we'd like to do something like this but there
      // are some problems to review that we don't want to get to now
      // so a new TYPE implies a new case in this function
      // if (type in this.pathSectionStylingFunctionsTable) {
      //   styles.push(
      //     this.pathSectionStylingFunctionsTable[type](
      //       coordinates,
      //       color,
      //       resolution,
      //       showNodes,
      //       showLabels,
      //       label
      //     )
      //   );
      // } else {
      //   styles.push(
      //     this.pathSectionStylingFunctionsTable[STYLE_TYPE_DEFAULT](
      //       coordinates,
      //       color,
      //       resolution,
      //       showNodes,
      //       showLabels,
      //       label
      //     )
      //   );
      // }

      if (type == EDGE_TYPE_OVERHEAD) {
        styles.push(
          ...this.ohPathStyle(
            coordinates,
            color,
            resolution,
            showNodes,
            showLabels,
            label,
            strokeWidth
          )
        );
      } else if (type == EDGE_TYPE_TUNNEL) {
        styles.push(
          ...this.tunPathStyle(
            coordinates,
            color,
            resolution,
            showNodes,
            showLabels,
            label,
            strokeWidth
          )
        );
      } else if (type == EDGE_TYPE_UNDER) {
        styles.push(
          ...this.ecPathStyle(
            coordinates,
            color,
            resolution,
            showNodes,
            showLabels,
            label,
            strokeWidth
          )
        );
      }

      return styles;
    };

    this.generateStyleForTechnology = (
      coordinates,
      type,
      color,
      resolution,
      showNodes,
      showLabels,
      label
    ) => {
      let styles = [];

      if (showLabels) {
        styles.push(
          new Style({
            text: new Text({
              font: "16px sans-serif",
              fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
              stroke: new Stroke({
                color: "rgba(0, 0, 0, 0.8)",
                width: 1
              }),
              text: label,
              // offsetX: -TECH_LABEL_OFFSET_X,
              // offsetY: -TECH_LABEL_OFFSET_Y,
              placement: "line",
              textBaseline: "bottom",
              overflow: true,
              maxAngle: 0.35,
              rotation: 0
            }),
            zIndex: Z_INDEX_LABELS_TECHNOLOGIES,
            geometry: new LineString(coordinates)
          })
        );
      }

      // Commented out for later use in case we need to style
      // techonlogies:

      // if (type == EDGE_TYPE_OVERHEAD) {
      //   styles.push(
      //     ...this.ohPathStyle(coordinates, color, resolution, showNodes, showLabels, label),
      //   );
      // } else if (type == EDGE_TYPE_TUNNEL) {
      //   styles.push(
      //     ...this.tunPathStyle(coordinates, color, resolution, showNodes, showLabels, label),
      //   );
      // } else if (type == EDGE_TYPE_UNDER) {
      //   styles.push(
      //     ...this.ecPathStyle(coordinates, color, resolution, showNodes, showLabels, label),
      //   );
      // }

      return styles;
    };

    this.tunPathStyle = (
      coordinates,
      color,
      resolution,
      showNodes,
      showLabels,
      label = "",
      strokeWidth = 3
    ) => {
      let styles = [];

      // The colored stroked line for our path
      const baseLineStyle = new Style({
        stroke: new Stroke({
          color: "rgba(255,255,255, 1)",
          width: strokeWidth
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        }),
        geometry: new LineString(coordinates)
      });

      // The colored stroked line for our path
      const lineStyle = new Style({
        stroke: new Stroke({
          color: color,
          lineDash: [8, 16],
          lineCap: "square",
          width: strokeWidth + 3
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        }),
        geometry: new LineString(coordinates)
      });

      const labelStyle = new Style({
        text: new Text({
          font: "14px sans-serif",
          fill: new Fill({ color: "rgba(255,255,255,1.0)" }),
          stroke: new Stroke({ color: color, width: 2 }),
          text: "Tunnel",
          offsetX: -LABEL_OFFSET_X,
          offsetY: -LABEL_OFFSET_Y,
          placement: "line",
          overflow: true,
          maxAngle: 10,
          rotation: 0
        }),
        zIndex: Z_INDEX_LABELS_SECTIONS,
        geometry: new LineString(coordinates)
      });

      styles.push(baseLineStyle);
      styles.push(lineStyle);
      if (showLabels) {
        // styles.push(labelStyle);
      }

      return styles;
    };

    this.ecPathStyle = (
      coordinates,
      color,
      resolution,
      showNodes,
      showLabels,
      label = "",
      strokeWidth = 3
    ) => {
      let styles = [];
      const newCoordinates = getBezier(coordinates);

      // The colored stroked line for our path
      const baseLineStyle = new Style({
        stroke: new Stroke({
          color: "rgba(255,255,255, 1)",
          width: strokeWidth
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        }),
        geometry: new LineString(newCoordinates)
      });

      // The colored stroked line for our path
      const lineStyle = new Style({
        stroke: new Stroke({
          color: color,
          lineDash: [3, 5],
          width: strokeWidth
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        }),
        geometry: new LineString(newCoordinates)
      });

      const labelStyle = new Style({
        text: new Text({
          font: "14px sans-serif",
          fill: new Fill({ color: "rgba(255,255,255,1.0)" }),
          stroke: new Stroke({ color: color, width: 2 }),
          text: "Earth Cable",
          offsetX: -LABEL_OFFSET_X,
          offsetY: -LABEL_OFFSET_Y,
          placement: "line",
          overflow: true,
          maxAngle: 10,
          rotation: 0
        }),
        zIndex: Z_INDEX_LABELS_SECTIONS,
        geometry: new LineString(newCoordinates)
      });

      styles.push(baseLineStyle);
      styles.push(lineStyle);

      if (showLabels) {
        // styles.push(labelStyle);
      }

      return styles;
    };

    this.ohPathStyle = (
      coordinates,
      color,
      resolution,
      showNodes,
      showLabels,
      label = "",
      strokeWidth = 3
    ) => {
      let styles = [];
      // let width = 3;

      // Override show nodes at high resolutions we do that to simply avoid
      // presenting too many point features at high zoom levels where they do
      // not provide any new kind of information
      if (resolution > NODE_VISIBILITY_RESOLUTION_THRESHOLD) {
        showNodes = false;
      }

      // The colored stroked line for our path
      const linestyle = new Style({
        stroke: new Stroke({
          color: color,
          lineDash: null,
          width: strokeWidth
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        }),
        geometry: new LineString(coordinates)
      });

      // The Nodes
      const nodestyle = new Style({
        stroke: new Stroke({
          color: color,
          width: 2
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        }),
        zIndex: 2,
        geometry: feature => {
          let ftCoords = coordinates;
          let points = [];

          for (let ptIdx = 0; ptIdx < ftCoords.length; ptIdx++) {
            points.push(
              this.ResolutionAwareNodeGeometry(ftCoords[ptIdx], resolution)
            );
          }

          return new GeometryCollection(points);
        }
      });

      const labelStyle = new Style({
        text: new Text({
          font: "14px sans-serif",
          fill: new Fill({ color: "rgba(255,255,255,1.0)" }),
          stroke: new Stroke({ color: color, width: 2 }),
          text: "OverHead",
          // offsetX: -LABEL_OFFSET_X,
          // offsetY: -LABEL_OFFSET_Y,
          placement: "line",
          baseline: "Bottom",
          overflow: true,
          maxAngle: 10,
          rotation: 0
        }),
        zIndex: Z_INDEX_LABELS_SECTIONS,
        geometry: new LineString(coordinates)
      });

      styles.push(linestyle);
      if (showNodes) {
        styles.push(nodestyle);
      }

      if (showLabels) {
        // styles.push(labelStyle);
      }

      return styles;
    };

    /**
     * Returns true if the feature has a gRoutingWitdhM property
     */
    this.hasRoutingWidth = feature => {
      return feature.get("gRoutingWidthM") ? true : false;
    };

    /**
     * Return the expected strokewidth of a given feature
     * This is governed by the gRoutingWidthM feature attribute if present,
     * otherwise it will return a default value
     */
    this.getStrokeWidth = (feature, resolution) => {
      const extent = feature.getGeometry().getExtent();
      const pointResolution = getPointResolution(
        "EPSG:3857",
        resolution,
        extent
      );

      let strokeWidth = feature.get("gRoutingWidthM")
        ? feature.get("gRoutingWidthM") / pointResolution
        : 3;

      strokeWidth = Math.max(strokeWidth, 3);
      return strokeWidth;
    };

    /**
     * Add a line style with the routing width
     */
    this.genRoutingWidthStyle = (color, width) => {
      // The colored stroked line for our path
      const linestyle = new Style({
        stroke: new Stroke({
          color: color,
          lineDash: null,
          width: width
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 1)"
        })
        // geometry: new LineString(coordinates)
      });

      return linestyle;
    };

    /**
     * Return a point style representing a connection point from the given
     * sectionType to the next sectionType
     */
    this.generateStyleForConnectionPoint = (
      coordinate,
      typeFrom,
      typeTo,
      attribute = "TYPE"
    ) => {
      let connectorImage = null;
      let styles = [];

      if (attribute === "TECH") {
        connectorImage = "assets/img/connpoint00.png"; // cross
      }
      if (
        (typeFrom == EDGE_TYPE_OVERHEAD && typeTo == EDGE_TYPE_UNDER) ||
        (typeFrom == EDGE_TYPE_UNDER && typeTo == EDGE_TYPE_OVERHEAD)
      ) {
        connectorImage = "assets/img/connpoint01.png"; // red circle
      } else if (
        (typeFrom == EDGE_TYPE_OVERHEAD && typeTo == EDGE_TYPE_TUNNEL) ||
        (typeFrom == EDGE_TYPE_TUNNEL && typeTo == EDGE_TYPE_OVERHEAD)
      ) {
        connectorImage = "assets/img/connpoint01.png"; // red circle
      } else if (
        (typeFrom == EDGE_TYPE_UNDER && typeTo == EDGE_TYPE_TUNNEL) ||
        (typeFrom == EDGE_TYPE_TUNNEL && typeTo == EDGE_TYPE_UNDER)
      ) {
        connectorImage = "assets/img/connpoint00.png"; // cross
      }

      if (connectorImage) {
        styles.push(
          new Style({
            geometry: new Point(coordinate),
            image: new Icon({
              src: connectorImage
            }),
            zIndex: Z_INDEX_CONNECTORS
          })
        );
      }
      return styles;
    };

    /**
     * Return a list of styles according to a given attribute name
     */

    /**
     * Given a feature representing a path and the Pathfinder metadata format
     * generate a list of styles to apply, according to one of the attributes
     * in the node metadata. For now only TYPE is supported
     *
     * @param {Object}  metadata what type we'll assign to those coordinates
     * @param {String}  color color to override in the style
     * @param {Boolean} showNodes Present the Nodes style or not
     * @param {Boolean} showLabels Present the Nodes style or not
     * @param {string}  [attribute=TYPE] the Node attribute in Metadata to use to select the style to apply
     *
     * @returns {Array} Array of styles
     */
    this.genComplexPathStyles = (
      metadata,
      color,
      showNodes,
      showLabels,
      showRoutingWidth,
      availableTechnologies = [],
      attribute = "TYPE"
    ) => {
      return (feature, resolution) => {
        // Either apply the property coming from a first call to this function
        // or if it has been set in the feature properties, us the feature
        // properties option

        let applyColor = feature.get("color") || color;
        let applyShowNodes =
          feature.get("showNodes") !== undefined
            ? feature.get("showNodes")
            : showNodes;
        let applyShowLabels =
          feature.get("showLabels") !== undefined
            ? feature.get("showLabels")
            : showLabels;
        let applyShowRoutingWidth =
          feature.get("showRoutingWidth") !== undefined
            ? feature.get("showRoutingWidth")
            : showRoutingWidth;
        let applyEditMode =
          feature.get("editMode") !== undefined
            ? feature.get("editMode")
            : false;

        let coordinates = feature.getGeometry().getCoordinates();
        let md = metadata["root"];

        let sectionType = null;
        let sliceFrom = 0;
        let sliceTechFrom = 0;
        let styles = []; // Final Styles

        // Styling of the path sections (T, O, U) with an
        // almost transparent style, but to be able to select
        // it.
        if (feature.get("gIsPathSection")) {
          styles.push(this.sectionNotSelected);
        }

        // Same here with the technology sections.
        if (feature.get("gIsTechnology")) {
          styles.push(this.sectionNotSelected);
        }

        // Styling of the main path linestring.
        if (feature.get("gIsPath")) {
          if (this.hasRoutingWidth(feature) && applyShowRoutingWidth) {
            const strokeWidth = this.getStrokeWidth(feature, resolution);
            styles.push(this.genRoutingWidthStyle(color, strokeWidth));
          }

          if (applyEditMode) {
            styles.push(this.pathActive);
          }

          // First type
          sectionType = md[0]["node"][attribute];
          let technologyType = md[0]["node"]["TECH"];
          let currentTechnology = md[0]["node"]["TECH"];

          for (let iNode = 0; iNode < coordinates.length - 1; iNode++) {
            const currentType = md[iNode]["node"][attribute];
            currentTechnology = md[iNode]["node"]["TECH"];

            // If thechnology change, add a connection point.
            if (currentTechnology != technologyType) {
              const technology = availableTechnologies.find(
                t => t.name === technologyType
              );

              const sectionName =
                md[sliceTechFrom]["node"]["SECTION_TECH_DATA"];

              styles.push(
                ...this.generateStyleForTechnology(
                  coordinates.slice(sliceTechFrom, iNode + 1),
                  technologyType,
                  applyColor,
                  resolution,
                  applyShowNodes,
                  applyShowLabels,
                  sectionName?.name || technology?.ui_name
                )
              );
              technologyType = currentTechnology;
              if (currentType === sectionType) {
                styles.push(
                  ...this.generateStyleForConnectionPoint(
                    coordinates[iNode],
                    technologyType,
                    technologyType,
                    "TECH"
                  )
                );
              }
              sliceTechFrom = iNode;
            }
            // End of a section
            if (currentType != sectionType) {
              styles.push(
                ...this.generateStyleForSection(
                  coordinates.slice(sliceFrom, iNode + 1),
                  sectionType,
                  applyColor,
                  resolution,
                  applyShowNodes,
                  applyShowLabels,
                  sliceFrom.toString() //possible label in metadata=,
                )
              );

              //generate a single sytle for a connection point
              styles.push(
                ...this.generateStyleForConnectionPoint(
                  coordinates[iNode],
                  sectionType,
                  currentType
                )
              );

              // Ready for the next
              sliceFrom = iNode;
              sectionType = currentType;
            }
          }

          // Last stretch that we need to append too
          if (sliceFrom < coordinates.length) {
            styles.push(
              ...this.generateStyleForSection(
                coordinates.slice(sliceFrom, coordinates.length),
                sectionType,
                applyColor,
                resolution,
                applyShowNodes,
                applyShowLabels,
                sliceFrom.toString() //possible label in metadata,
              )
            );

            const technology = availableTechnologies.find(
              t => t.name === technologyType
            );

            const sectionName = md[sliceTechFrom]["node"]["SECTION_TECH_DATA"];
            styles.push(
              ...this.generateStyleForTechnology(
                coordinates.slice(sliceTechFrom, coordinates.length),
                technologyType,
                applyColor,
                resolution,
                applyShowNodes,
                applyShowLabels,
                sectionName?.name || technology?.ui_name
              )
            );
          }
        }

        // Styling of the pylon features when highlighted because
        // using the pylon tools (move and info).
        if (feature.get("gIsPylon")) {
          // this is our points
          let applyEditMode =
            feature.get("editMode") !== undefined
              ? feature.get("editMode")
              : false;
          let applyNewMode =
            feature.get("gIsNewNode") !== undefined
              ? feature.get("gIsNewNode")
              : false;
          if (applyEditMode && !applyNewMode) {
            styles.push(this.nodeActive(feature, resolution, "split"));
          }

          if (applyNewMode) {
            styles.push(this.pylonNewStyle(feature, resolution));
          }

          // If splitMode active
          let applySplitMode =
            feature.get("splitMode") !== undefined
              ? feature.get("splitMode")
              : false;
          if (applySplitMode) {
            styles.push(this.nodeActive(feature, resolution, "split"));
          }

          // If joinMode active
          let applyJoinMode =
            feature.get("joinMode") !== undefined
              ? feature.get("joinMode")
              : false;
          if (applyJoinMode) {
            styles.push(this.nodeActive(feature, resolution, "join"));
          }
        }

        return styles;
      };
    };

    /**
     * Style for drawing path pylons
     * @param {*} color
     * @param {list of metadata records} metadata Dictionary structure as described by BE api
     * @param {int} type of edges to use for geometry filtering
     * @param {boolean} reverse If true, the selected edges will have the given type; if false, any other type
     * @returns Style
     */
    this.pylonsStyle = (color, metadata, type, reverse = false) => {
      return new Style({
        image: new Circle({
          radius: 4,
          stroke: new Stroke({
            color: color,
            width: 2
          }),
          fill: new Fill({
            color: "rgba(255,255,255,0.4)"
          })
        }),
        zIndex: 2,
        geometry: feature => {
          let coordinates = feature.getGeometry().getCoordinates();
          let points = [];
          let md = metadata["root"];

          if (coordinates.length > 0 && Array.isArray(coordinates[0])) {
            // issue#2448
            for (let iEdge = 0; iEdge < coordinates.length; iEdge++) {
              let md_type = EDGE_TYPE_OVERHEAD;
              md_type =
                iEdge == coordinates.length - 1
                  ? md[iEdge - 1]["node"]["TYPE"]
                  : md[iEdge]["node"]["TYPE"];
              let include_geom =
                (!reverse && md_type == type) || (reverse && md_type != type);
              if (include_geom) points.push(new Point(coordinates[iEdge]));
            }
          }
          return new GeometryCollection(points);
        }
      });
    };

    /**
     * Selected pylon style (move action)
     * @returns
     */
    this.pylonHighlightStyle = (feature, resolution) => {
      let fill = new Fill({
        color: "rgba(255,255,255,0.4)"
      });
      let stroke = new Stroke({
        color: "#FFFF00",
        width: 4
      });
      return new Style({
        geometry: this.ResolutionAwareNodeGeometry(
          feature.getGeometry().getCoordinates(),
          resolution
        ),
        fill: fill,
        stroke: stroke,
        zIndex: 3
      });
    };

    /**
     * Selected pylon style (move action)
     * @returns
     */
    this.pylonNewStyle = (feature, resolution) => {
      let fill = new Fill({
        color: "rgba(255,255,255,0.4)"
      });
      let stroke = new Stroke({
        color: "rgba(236,114,47, 1)",
        width: 4
      });
      return new Style({
        geometry: this.ResolutionAwareNodeGeometry(
          feature.getGeometry().getCoordinates(),
          resolution
        ),
        fill: fill,
        stroke: stroke,
        zIndex: 3
      });
    };
    /**
     * Pylon information style
     * @returns a style to apply to present information about a pylon
     */
    this.pylonHoverStyle = (feature, resolution) => {
      let fill = new Fill({
        color: "rgba(255,255,255,0.4)"
      });
      let stroke = new Stroke({
        color: "#FFFF00",
        width: 20
      });
      return new Style({
        fill: fill,
        stroke: stroke,
        geometry: this.ResolutionAwareNodeGeometry(
          feature.getGeometry().getCoordinates(),
          resolution
        ),
        zIndex: 3
      });
    };

    /**
     * Style definition for location search (geocoder) point
     */
    this.styleLocationSearch = new Style({
      image: new Icon({
        anchorOrigin: "bottom-left",
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        anchor: [0.5, -0.2],
        scale: 0.1,
        color: "#ffcc00",
        src: "./iconW.png"
      })
    });

    /**
     * Selected feature style (flyTo point)
     * @returns
     */
    this.featureSelectedStyle = color => {
      let fill = new Fill({
        color: "rgba(231, 247, 113, 0.5)"
      });
      let stroke = new Stroke({
        color: "rgba(231, 247, 113, 1)"
      });
      return new Style({
        image: new Circle(),
        fill: fill,
        stroke: stroke,
        zIndex: 3
      });
    };

    /**
     * Comments style is defined depending on clusters or single features. When
     * a single feature is found an icon for that comment should be set up,
     * while clusters are represented as a circle sized depending on the amount
     * of comments it groups.
     *
     * @param {*} feature
     * @returns Style
     */
    this.styleComment = feature => {
      const features = feature.get("features");
      let size = 1;

      if (features !== undefined) {
        size = features.length;
      }

      const pinColor = () => {
        if (feature.get("features")[0].get("selected")) {
          return "#ffffff";
        } else {
          return feature.get("features")[0].get("pf_thread_color") || "#00B2CA";
        }
      };

      const selectedColor = `./comment-pin-${feature
        .get("features")[0]
        .get("pf_thread_color")}.png`;

      if (size == 1) {
        // A comment thread
        return new Style({
          image: new Icon({
            color: pinColor(),
            anchorOrigin: "bottom-left",
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            anchor: [0.5, -0.2],
            src: feature.get("features")[0].get("selected")
              ? `./comment-pin-${feature
                  .get("features")[0]
                  .get("pf_thread_color")
                  .substring(1)}.png`
              : "./comment-pin-white-black.png",
            opacity: 1,
            scale: feature.get("features")[0].get("hovered")
              ? 0.6
              : feature.get("features")[0].get("selected")
                ? 0.8
                : 0.5
          })
        });
      } else {
        // A cluster, just the default style from the OL Cluster example,
        // we'll want to improve this for sure. This will show clusters as
        // a round bubble with the number of comments in that cluster
        return new Style({
          image: new Circle({
            radius: Math.min(10 + size, 30),
            stroke: new Stroke({
              color: "#fff"
            }),
            fill: new Fill({
              color: "#00BEF3"
            })
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: "#fff"
            })
          })
        });
      }
    };
    this.pathActive = new Style({
      fill: new Fill({
        color: "#FFFF00"
      }),
      stroke: new Stroke({
        color: "#FFFF00",
        width: 4
      }),
      image: new CircleStyle({
        radius: 3 * 2,
        fill: new Fill({
          color: "#FFFF00"
        }),
        stroke: new Stroke({
          color: "#FFFF00",
          width: 3 / 2
        })
      })
    });

    this.modifyStyle = new Style({
      image: new CircleStyle({
        radius: 3 * 2,
        fill: new Fill({
          color: "rgba(236,114,47, 1)"
        }),
        stroke: new Stroke({
          color: "rgba(236,114,47, 1)",
          width: 3 / 2
        })
      }),
      zIndex: Infinity
    });

    this.selectModify = (feature, resolution) => {
      let styles = [];
      styles.push(this.pylonHighlightStyle(feature, resolution));

      return styles;
    };
    // Almost non-visible style, but it's available here so OL can
    // perform selections.

    this.sectionNotSelected = new Style({
      fill: new Fill({
        color: "rgba(0,0,0,0.1)"
      }),
      stroke: new Stroke({
        color: "rgba(0,0,0,0.1)",
        width: 3
      })
    });

    this.pathSelected = new Style({
      fill: new Fill({
        color: "rgba(255,255,0,1)"
      }),
      stroke: new Stroke({
        color: "rgba(255,255,0,0.8)",
        width: 15
      })
    });

    this.pathSectionSelected = new Style({
      fill: new Fill({
        color: "rgba(255,255,0,1)"
      }),
      stroke: new Stroke({
        color: "rgba(255,255,0,0.8)",
        width: 15
      })
    });

    this.technologySelected = new Style({
      fill: new Fill({
        color: "rgba(255,255,0,1)"
      }),
      stroke: new Stroke({
        color: "rgba(255,255,0,0.8)",
        width: 15
      })
    });

    this.nodeClicked = (feature, resolution) => {
      let fill = new Fill({
        color: "rgba(255,255,255,0.4)"
      });
      let stroke = new Stroke({
        color: "rgba(255,255,0,1)",
        width: 15
      });
      return new Style({
        geometry: this.ResolutionAwareNodeGeometry(
          feature.getGeometry().getCoordinates(),
          resolution
        ),
        fill: fill,
        stroke: stroke,
        zIndex: 3
      });
    };

    this.nodeSelected = (feature, resolution, type) => {
      let fill = new Fill({
        color: "rgba(255,255,255,0.4)"
      });
      let stroke = new Stroke({
        color: "rgba(236,114,47,1)",
        width: type === "split" ? 15 : 30
      });
      return new Style({
        geometry: this.ResolutionAwareNodeGeometry(
          feature.getGeometry().getCoordinates(),
          resolution
        ),
        fill: fill,
        stroke: stroke,
        zIndex: 3
      });
    };

    this.nodeActive = (feature, resolution, type) => {
      const isHovered = feature.get("hovered");

      const color = isHovered ? "rgba(255,255,0,1)" : "rgba(255,255,0,0.8)";

      let fill = new Fill({
        color: isHovered ? "rgba(0,0,0,1)" : "rgba(255,255,255,0.6)"
      });
      let stroke = new Stroke({
        color: color,
        width: type === "split" ? (isHovered ? 15 : 8) : isHovered ? 30 : 16
      });

      return new Style({
        fill: fill,
        stroke: stroke,
        geometry: this.ResolutionAwareNodeGeometry(
          feature.getGeometry().getCoordinates(),
          resolution
        ),
        zIndex: 3
      });
    };
  }
}
