import React, { useEffect, useContext, useState, createContext } from "react";

import PropTypes from "prop-types";

import ReactGA from "react-ga4";
import getenvvar from "utils/Env";

const GoogleAnalyticsID = getenvvar("REACT_APP_GOOGLE_ANALYTICS");

const CookieConsentContext = createContext({});

const HOTJAR_SCRIPT_ID = "hotjar-script";

function addGoogleAnalyticsScript() {
  if (
    ReactGA.isInitialized === false &&
    GoogleAnalyticsID &&
    GoogleAnalyticsID.trim() !== ""
  ) {
    // Only initialize Google Analytics if the ID is set (a valid non-empty string)
    ReactGA.initialize(GoogleAnalyticsID);
  }
}

function addHotJarScript() {
  const alreadyExistsHotJarScript = !!document.getElementById(HOTJAR_SCRIPT_ID);

  if (alreadyExistsHotJarScript) return;

  const scriptHotJar = document.createElement("script");
  scriptHotJar.id = HOTJAR_SCRIPT_ID;
  scriptHotJar.type = "text/javascript";
  const scriptHotJarTextContent =
    document.createTextNode(`(function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 3646529, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`);
  scriptHotJar.appendChild(scriptHotJarTextContent);

  document.body.appendChild(scriptHotJar);
}

function removeScripts() {
  const hotJarScript = document.getElementById(HOTJAR_SCRIPT_ID);

  if (ReactGA.isInitialized) {
    ReactGA.reset();
  }

  if (hotJarScript) {
    hotJarScript.remove();
  }
}

export function CookieConsentProvider({ children }) {
  const [cookieConsentChoice, setCookieConsentChoice] = useState("undecided");

  useEffect(() => setCookieConsentChoice(getCookieConsent()), []);

  useEffect(() => {
    if (
      cookieConsentChoice === "undecided" ||
      cookieConsentChoice === "declined"
    ) {
      removeScripts();
    } else {
      addGoogleAnalyticsScript();
      addHotJarScript();
    }
  }, [cookieConsentChoice]);

  function updateCookieConsent(consent) {
    localStorage.setItem("CookieConsent", consent);

    setCookieConsentChoice(consent);
  }

  return (
    <CookieConsentContext.Provider
      value={{
        cookieConsentChoice,
        acceptCookieConsent: () => updateCookieConsent("accepted"),
        declineCookieConsent: () => updateCookieConsent("declined")
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
}

CookieConsentProvider.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired
};

export function useCookieConsent() {
  return useContext(CookieConsentContext);
}

function getCookieConsent() {
  return localStorage.getItem("CookieConsent") || "undecided";
}
