/**
 * Style function utils, mostly aimed at modifying a given line
 * to provide a better styling (Curves)
 * @author Salvador Bayarri
 *
 */

/**
 * Simple linear interpolation to calculate Bezier
 * @param {float} x1: initial value
 * @param {float} x2: final value
 * @param {float} t: interpolation factor between 0 (x1) and 1 (x2)
 * @returns float
 */
export const linearInterpolation = (x1, x2, t) => {
  return x1 + (x2 - x1) * t;
};

/**
 * Simple cubic Bezier interpolation for lines.
 * See https://medium.com/@adrian_cooney/bezier-interpolation-13b68563313a
 * @param {list of 2D coordinates} line: section line as a list of points
 * @returns list of 2D coordinates
 */
export const getBezier = line => {
  let tangent1x = line[1][0] - line[0][0];
  let tangent1y = line[1][1] - line[0][1];
  let tangent2x;
  let tangent2y;
  let nPoints = 10; // nPoints to represent each edge
  let tIncrease = 1.0 / nPoints;
  let roundness = 0.2;
  let outLine = [];
  outLine.push(line[0]);
  for (let iEdge = 0; iEdge < line.length - 1; iEdge++) {
    if (iEdge > 0) {
      tangent1x = line[iEdge + 1][0] - line[iEdge - 1][0];
      tangent1y = line[iEdge + 1][1] - line[iEdge - 1][1];
    }
    if (iEdge == line.length - 2) {
      tangent2x = line[iEdge + 1][0] - line[iEdge][0];
      tangent2y = line[iEdge + 1][1] - line[iEdge][1];
    } else {
      tangent2x = line[iEdge + 2][0] - line[iEdge][0];
      tangent2y = line[iEdge + 2][1] - line[iEdge][1];
    }
    // define intermediate control points
    let control1x = line[iEdge][0] + tangent1x * roundness;
    let control1y = line[iEdge][1] + tangent1y * roundness;
    let control2x = line[iEdge + 1][0] - tangent2x * roundness;
    let control2y = line[iEdge + 1][1] - tangent2y * roundness;
    // interpolate points
    for (let ipt = 1; ipt < nPoints; ipt++) {
      let t = ipt * tIncrease;
      // first linear interpolation
      let ax = linearInterpolation(line[iEdge][0], control1x, t);
      let ay = linearInterpolation(line[iEdge][1], control1y, t);
      let bx = linearInterpolation(control1x, control2x, t);
      let by = linearInterpolation(control1y, control2y, t);
      let cx = linearInterpolation(control2x, line[iEdge + 1][0], t);
      let cy = linearInterpolation(control2y, line[iEdge + 1][1], t);
      // second linear interpolation
      let dx = linearInterpolation(ax, bx, t);
      let dy = linearInterpolation(ay, by, t);
      let ex = linearInterpolation(bx, cx, t);
      let ey = linearInterpolation(by, cy, t);
      // final interpolation
      let fx = linearInterpolation(dx, ex, t);
      let fy = linearInterpolation(dy, ey, t);
      outLine.push([fx, fy]);
    }
    outLine.push(line[iEdge + 1]);
  }
  return outLine;
};
